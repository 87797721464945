<template>
	<MobileAppWizardStep
		v-model="application"
		:steps="steps"
		:start-with-last="startWithLast"
		:step-name="stepName"
		@previous="previous"
		@next="next"
		@unvalidated="unvalidateSteps"
		@validated="validateSteps"
	/>
</template>

<script>
export default {
	name: 'MobileAppStepAppleAppStore',
	components: {
		MobileAppWizardStep: () => ({
			component: import('@/components/SuperAdmin/MobileApp/CreationSteps/MobileAppWizardStep')
		})
	},
	props: {
		value: {
			required: true,
			type: Object
		},
		startWithLast: {
			required: false,
			type: Boolean,
			default: false
		},
		stepName: {
			required: true,
			type: String
		}
	},
	data: function () {
		return {
			stepsValidated: false,
			steps: [
				{
					title: this.$t('mobileapp.creationSteps.followNotionArticle'),
					href: () =>
						'https://www.notion.so/welyb/Etape-1-Cr-ation-de-la-fiche-de-l-application-mobile-sur-l-AppStore-Apple-12f72a54007580358639fb0defbb8870?pvs=4',
					valuesToCopy: () => [
						{ name: this.$t('mobileapp.creationSteps.ios.title'), value: this.application.title },
						{ name: this.$t('mobileapp.creationSteps.ios.subtitle'), value: this.application.subtitle },
						{ name: this.$t('mobileapp.creationSteps.ios.ugs'), value: this.application.bundle_id }
					],
					component: () => import('@/components/SuperAdmin/MobileApp/Details/MobileAppAppStoreID')
				}
			]
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		validated: function () {
			return this.stepsValidated && typeof this.application.app_store_id === 'string'
				? this.application.app_store_id.trim().length > 0
				: this.application?.app_store_id > 0
		}
	},
	watch: {
		application: {
			deep: true,
			immediate: true,
			handler: function () {
				this.validate()
			}
		},
		stepsValidated: {
			immediate: true,
			handler: function () {
				this.validate()
			}
		}
	},
	methods: {
		previous: function () {
			this.$emit('previous')
		},
		next: function () {
			this.$emit('next')
		},
		unvalidateSteps: function () {
			this.stepsValidated = false
		},
		validateSteps: function () {
			this.stepsValidated = true
		},
		validate: function () {
			if (this.validated) {
				this.$emit('validated')
			} else {
				this.$emit('unvalidated')
			}
		}
	}
}
</script>
